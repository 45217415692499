











































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































import Vue from 'vue';
import FormSwitch from '@/components/form/form-switch.vue';
import DatePicker from '@/components/form/date-picker.vue';
import { calculateClaimsRequirementPremium,
  getProductPageBeforePriceTabHelper,
  onCurrencyFocus,
  recursiveFillData,
  deepEqual,
  copyObject } from '@/helper/functions';
import popUpModal from '@/components/form/pop-up-modal.vue';
import apiBackend from '@/service/api-insurance';
import html2pdf from 'html2pdf.js';
import xmlStatusIcon from '@/components/form/XML-status-icon.vue';
import { dataChangeMixin } from '@/mixins/dataChangeMixin';
// import priceStatusIcon from '@/components/form/price-status-icon.vue';

interface PriceItem {
  machineCodeNumber: string;
  codeSFPF: string;
  codeABMGDiebstahl: string;
  assessmentYear: string;
  evaluationPrice: string;
  codeVSu: string;
  codeSachAus: string;
  codeDeductibleVar1: number | string;
  CodeSbBuVar1: string;
  discount1: number | string;
  surcharge3: string;
}

const priceResponseDataObject = {
  SBTarif: {
  },
  SBVariante1: {
  },
  SBVariante2: {
  },
  SBVariante3: {
  },
};

const promotionalRewardsItem = {
  name: 'Tarif- SB',
  selbstbehalt: '',
  pramieInklTariflicher: 0,
  pramieInklVorschadenfaktor: 0,
  pramieInklSkill: 0,
  angebotspramiensatzNetto: '',
  angebotspramiensatzBrutto: '',
  beitragCtKwNetto: 0,
  sentXmlRequest: false,
};

const variantItem = {
  tarifPramie: 0,
  laufzeit: 1,
  SVR: 0,
  betrachtungszeitraum: '',
  SQ: '',
  nettoisierung: 0,
  pramieInklTariflicherNachlasse: 0,
  showInPdf: true,
};

export default Vue.extend({
  name: 'price-card',
  mixins: [dataChangeMixin],
  components: {
    // priceStatusIcon,
    FormSwitch,
    DatePicker,
    popUpModal,
    xmlStatusIcon,
  },
  props: [
    'id',
    'insuranceKey',
    'insuranceData',
    'processSavingData',
    'insuranceFormsApiData',
    'AMSAPISendStatus',
    'priceData',
  ],
  data(): any {
    return {
      name: 'price-card',
      key: 'WINDENERGIEANLAGEN_INSURANCE',
      modalOpen: false,
      modalTitle: '',
      modalBody: '',
      queue: [],
      pdfStyles: false,
      filledZusammenfassung: false,
      zusammenfassung: {
        versicherungsnehmer: '',
        betriebsart: '',
        versicherungssummeAktuell: 0,
        versicherungssumme371: 0,
        produkt: 'Windenergieanlagen',
        nettoisiert: '',
        steuerland: 'Deutschland',
        vorversicherer: '',
        versicherungsscheinnummerVorvertrag: '',
        PML: 0,
      },
      kalkulationsergebnis: {
        hazardGroups: {
          feuer: '',
          BU: '',
          maschinenBruch: '',
          bauleistungMontage: '',
        },
      },
      ergebnisseTarifkalkulation: {
        tarif: {
          name: 'SB-Tarif SB',
          selbstbehalt: '5.000 EUR / 7 tage',
          ...copyObject(variantItem),
          priceKey: 'tarif',
          priceKeyForCalculations: 'SBTarif',
        },
        SBVariante1: {
          name: 'SB-Variante 1',
          selbstbehalt: '',
          ...copyObject(variantItem),
          priceKey: 'variant1',
          priceKeyForCalculations: 'SBVariante1',
        },
        SBVariante2: {
          name: 'SB-Variante 2',
          selbstbehalt: '',
          ...copyObject(variantItem),
          priceKey: 'variant2',
          priceKeyForCalculations: 'SBVariante2',
        },
        SBVariante3: {
          name: 'SB-Variante 3',
          selbstbehalt: 'Misch SB-Variante',
          ...copyObject(variantItem),
          priceKey: 'variant3',
          priceKeyForCalculations: 'SBVariante3',
        },
      },
      vorschadenhistorie: {
        select: false,
        jahr1: {
          schaden: 0,
          reserven: 0,
        },
        jahr2: {
          schaden: 0,
          reserven: 0,
        },
        jahr3: {
          schaden: 0,
          reserven: 0,
        },
        jahr4: {
          schaden: 0,
          reserven: 0,
        },
        jahr5: {
          schaden: 0,
          reserven: 0,
        },
        summe: {
          schaden: 0,
          reserven: 0,
        },
        SBVorvertrag: 0,
        Betrachtungszeitraum: '',
        Zielschadenquote: '',
        MindestjahrespramieTarifVariante: '',
        MindestjahrespramieSBVariante1: '',
        MindestjahrespramieSBVariante2: '',
        MindestjahrespramieSBVariante3: '',
        ZusatzlicherVorschadenfaktor: 0,
        Begrundung: '',
      },
      skillVollmachten: {
        TariffVariant: {
          estates: 0,
          surcharges: 0,
          differenceСoverage: 0,
          disable: false,
        },
        SBvariant1: {
          estates: 0,
          surcharges: 0,
          differenceСoverage: 0,
          disable: false,
        },
        SBvariant2: {
          estates: 0,
          surcharges: 0,
          differenceСoverage: 0,
          disable: false,
        },
        SBvariant3: {
          estates: 0,
          surcharges: 0,
          differenceСoverage: 0,
          disable: false,
        },
        reason: '',
      },
      vierAugenPrinzip: {
        show: false,
        discussedWith: '',
        date: '',
      },
      alternativeDescriptionShow: false,
      coInsuranceShow: false,
      totalOutputOfSystemsInKw: 0,
      desiredContributionInCtKw: 0,
      desiredVariant: null,
      offerPriceCtKw: 0,
      coInsuranceTotalOutputOfSystemsInKw: 0,
      coInsuranceSurchargeInCtKw: 0,
      coInsuranceSurchargeInEur: 0,
      desiredVariants: [
        'Tarif Variante',
        'SB Variante 1',
        'SB Variante 2',
        'SB Variante 3',
      ],
      mapDesiredVariants: {
        'Tarif Variante': 'TariffVariant',
        'SB Variante 1': 'SBvariant1',
        'SB Variante 2': 'SBvariant2',
        'SB Variante 3': 'SBvariant3',
      },
      mapPromotionalRewards: {
        'Tarif Variante': 'tarif',
        'SB Variante 1': 'SBVariante1',
        'SB Variante 2': 'SBVariante2',
        'SB Variante 3': 'SBVariante3',
      },
      mapSkillVollmachtenToPromotionalRewards: {
        TariffVariant: 'tarif',
        SBvariant1: 'SBVariante1',
        SBvariant2: 'SBVariante2',
        SBvariant3: 'SBVariante3',
      },
      promotionalRewards: {
        tarif: {
          ...promotionalRewardsItem,
          name: 'Tarif- SB',
          selbstbehalt: '5.000 EUR / 7 tage',
        },
        SBVariante1: {
          ...promotionalRewardsItem,
          name: 'SB-Variante 1',
        },
        SBVariante2: {
          ...promotionalRewardsItem,
          name: 'SB-Variante 2',
        },
        SBVariante3: {
          ...promotionalRewardsItem,
          name: 'SB-Variante 3',
          selbstbehalt: 'Misch SB-Variante',
        },
      },
      maschinenItems: {
        tarif: {
          ...promotionalRewardsItem,
          name: 'Tarif- SB',
          selbstbehalt: '5.000 EUR / 7 tage',
        },
        SBVariante1: {
          ...promotionalRewardsItem,
          name: 'SB-Variante 1',
        },
        SBVariante2: {
          ...promotionalRewardsItem,
          name: 'SB-Variante 2',
        },
        SBVariante3: {
          ...promotionalRewardsItem,
          name: 'SB-Variante 3',
          selbstbehalt: 'Misch SB-Variante',
        },
      },
      erstrisikosummenPriceItems: {
        tarif: {
          ...promotionalRewardsItem,
          name: 'Tarif- SB',
          selbstbehalt: '5.000 EUR / 7 tage',
        },
        SBVariante1: {
          ...promotionalRewardsItem,
          name: 'SB-Variante 1',
        },
        SBVariante2: {
          ...promotionalRewardsItem,
          name: 'SB-Variante 2',
        },
        SBVariante3: {
          ...promotionalRewardsItem,
          name: 'SB-Variante 3',
          selbstbehalt: 'Misch SB-Variante',
        },
      },
      promotionalRewardsCssClasses: {
        tarif: 'bg-gray',
        SBVariante1: 'table-variant-1',
        SBVariante2: 'table-variant-2',
        SBVariante3: 'table-variant-3',
      },
      ergebnisseTarifkalkulationCssClasses: {
        tarif: 'bg-gray',
        SBVariante1: 'table-variant-1',
        SBVariante2: 'table-variant-2',
        SBVariante3: 'table-variant-3',
      },
      variant: 'tarif',
      hinweiseZumBeitrag: '',
      surchargeChangesManually: false,
      priceItemKey: '',
      /* priceResponseData: {
        SBTarif: {
        },
        SBVariante1: {
        },
        SBVariante2: {
        },
        SBVariante3: {
        },
      }, */
    };
  },
  watch: {
    ergebnisseTarifkalkulation: {
      handler() {
        this.calculatePromotionalRewards();
      },
      deep: true,
    },
    skillVollmachten: {
      handler() {
        this.calculatePromotionalRewards();
      },
      deep: true,
    },
    processSavingData(value: boolean) {
      if (value) {
        this.executeQueue();
      }
    },
    desiredContributionInCtKw(value: number) {
      this.offerPriceCtKw = ((+this.totalOutputOfSystemsInKw) * value) / 100;
    },
    'insuranceData.spreadsheetData': function (newValue, oldValue) {
      if (this.insuranceData && this.insuranceData.spreadsheetData && !deepEqual(newValue, oldValue)) {
        this.calculateTables(this.priceResponseData);
        this.fillZusammenfassung();
      }
    },
    priceResponseData: {
      handler(newValue) {
        this.calculateTables(newValue);
      },
      deep: true,
    },
    offerPriceCtKw() {
      this.calculateSurchargeAndEstates();
    },
    desiredVariant() {
      this.disableSkillVollmachtenInput();
      this.calculateSurchargeAndEstates();
    },
    totalOutputOfSystemsInKw() {
      this.calculateSurchargeAndEstates();
    },
    alternativeDescriptionShow(value: boolean) {
      this.calculateSurchargeAndEstates();
    },
    coInsuranceShow() {
      this.calculateSurchargeAndEstates();
    },
    coInsuranceSurchargeInEur(value: number) {
      this.calculateSurchargeAndEstates();
    },
    'priceData.tarif.priceResponse': function (newVal, oldVal) {
      this.fillPriceResponse(newVal, oldVal);
    },
    'priceData.variant1.priceResponse': function (newVal, oldVal) {
      this.fillPriceResponse(newVal, oldVal);
    },
    'priceData.variant2.priceResponse': function (newVal, oldVal) {
      this.fillPriceResponse(newVal, oldVal);
    },
    'priceData.variant3.priceResponse': function (newVal, oldVal) {
      this.fillPriceResponse(newVal, oldVal);
    },
  },
  computed: {
    sum371(): number {
      let sum = 0;

      this.positionsdatenItems.forEach((itm) => {
        sum += +itm.VSU371;
      });

      return sum;
    },
    priceResponseData(): any {
      return this.insuranceData?.spreadsheetData?.priceResponseData || copyObject(priceResponseDataObject);
    },
    deckungserweiterungensSurcharge(): Array<any> {
      const resultData: Array<any> = [];

      if (this.insuranceData?.spreadsheetData?.deckungserweiterungen) {
        resultData.push(
          {
            name: 'SB-Tarif SB',
            value: this.getDeckungserweiterungenSurchargeForShow(this.insuranceData.spreadsheetData.deckungserweiterungen, this.priceResponseData.SBTarif),
            objs: this.priceResponseData.SBTarif,
          },
        );
        resultData.push(
          {
            name: 'SB-Variante 1',
            value: this.getDeckungserweiterungenSurchargeForShow(this.insuranceData.spreadsheetData.deckungserweiterungen, this.priceResponseData.SBVariante1),
          },
        );
        resultData.push(
          {
            name: 'SB-Variante 2',
            value: this.getDeckungserweiterungenSurchargeForShow(this.insuranceData.spreadsheetData.deckungserweiterungen, this.priceResponseData.SBVariante2),
          },
        );
        resultData.push(
          {
            name: 'SB-Variante 3',
            value: this.getDeckungserweiterungenSurchargeForShow(this.insuranceData.spreadsheetData.deckungserweiterungen, this.priceResponseData.SBVariante3),
          },
        );
      }

      return resultData;
    },
    maschineAbweichendVomWindenergieanlagentarifItems(): any[] {
      return this.insuranceData?.spreadsheetData?.maschineAbweichendVomWindenergieanlagentarifItems || [];
    },
    positionsdatenItems(): any[] {
      return this.insuranceData?.spreadsheetData?.positionsdatenItems || [];
    },
    zahlungsweise(): string|null {
      return this.insuranceData?.spreadsheetData?.zahlungsweise;
    },
    faktorenstand(): string|null {
      return this.insuranceData?.spreadsheetData?.Faktorenstand;
    },
    erstrisikosummenItems(): any[] {
      return this.insuranceData?.spreadsheetData?.erstrisikosummenItems;
    },
    selbstbeteiligungsvariantenEinspeisevergutung(): any {
      return this.insuranceData?.spreadsheetData?.selbstbeteiligungsvariantenEinspeisevergutung;
    },
    spreadsheetData(): any {
      return this.insuranceData?.spreadsheetData;
    },
    vorschadenhistorieSummeSchaden(): number {
      return (+this.vorschadenhistorie.jahr1.schaden) +
        (+this.vorschadenhistorie.jahr2.schaden) +
        (+this.vorschadenhistorie.jahr3.schaden) +
        (+this.vorschadenhistorie.jahr4.schaden) +
        (+this.vorschadenhistorie.jahr5.schaden);
    },
    vorschadenhistorieSummeReserven(): number {
      return (+this.vorschadenhistorie.jahr1.reserven) +
        (+this.vorschadenhistorie.jahr2.reserven) +
        (+this.vorschadenhistorie.jahr3.reserven) +
        (+this.vorschadenhistorie.jahr4.reserven) +
        (+this.vorschadenhistorie.jahr5.reserven);
    },
    // Schadenbedarfsprämie
    claimsRequirementPremium(): number {
      return calculateClaimsRequirementPremium(
        this.vorschadenhistorieSummeSchaden,
        this.vorschadenhistorieSummeReserven,
        +this.vorschadenhistorie.Betrachtungszeitraum,
        +this.vorschadenhistorie.Zielschadenquote,
      );
    },
    zusatzlicherVorschadenfaktorСoefficient(): number {
      return (100 - this.vorschadenhistorie.ZusatzlicherVorschadenfaktor) / 100;
    },
    isApiUser(): boolean {
      return this.$store.getters['authModule/isApiUser'];
    },
    isPCUser(): boolean {
      return this.$store.getters['authModule/isPCUser'];
    },
  },
  methods: {
    calculateTables(priceResponseData) {
      // this.kalkulationsergebnis.versicherungssummeAktuell = this.insuranceData.spreadsheetData.positionsdatenItems[0]?.versicherungssumme || null;
      this.fillAngebotspramien(this.insuranceData.spreadsheetData.selbstbeteiligungsvariantenEinspeisevergutung);
      this.fillErgebnisseTarifkalkulation(priceResponseData);
      this.fillApiValuesData();

      if (this.insuranceData?.spreadsheetData?.positionsdatenItems) {
        this.totalOutputOfSystemsInKw = this.calculateTotalOutputOfSystemsInKw(this.insuranceData.spreadsheetData.positionsdatenItems);
        this.coInsuranceTotalOutputOfSystemsInKw = this.totalOutputOfSystemsInKw;
        this.offerPriceCtKw = (this.totalOutputOfSystemsInKw * (+this.desiredContributionInCtKw)) / 100;
        this.calculateCoInsuranceSurchargeInEur();
      }
    },
    disableSkillVollmachtenInput() {
      Object.keys(this.skillVollmachten).forEach((key: string) => {
        if (this.skillVollmachten[key]?.disable) {
          this.skillVollmachten[key].disable = false;
        }
      });
      if (this.desiredVariant &&
        this.mapDesiredVariants[this.desiredVariant] &&
        this.promotionalRewards[this.mapPromotionalRewards[this.desiredVariant]]
      ) {
        const key = this.mapDesiredVariants[this.desiredVariant];
        this.skillVollmachten[key].disable = true;
      }
    },
    calculateSurchargeAndEstates() {
      if (!this.surchargeChangesManually) {
        this.setZeroToSkillVollmachten();
        this.calculateSkillPowersOfAttorney();
        this.calculateSkillSurchargesForCoInsurance(this.coInsuranceSurchargeInEur);
        this.calculatePromotionalRewards();
      }
    },
    onCurrencyFocus,
    getXmlBtnIsDisable(index: string) {
      if (this.AMSAPISendStatus !== null) {
        const status = this.AMSAPISendStatus[index];
        if (status !== null) {
          return (!status.dataUpdated && status.statusSuccess);
        }
      }
      return false;
    },
    onChangeSkillVollmachtenSurcharges() {
      this.surchargeChangesManually = true;
      this.coInsuranceSurchargeInCtKw = 0;
      this.coInsuranceSurchargeInEur = 0;
      this.surchargeChangesManually = false;
    },
    makePriceDataRequest(selbstbeteiligungsvariantenEinspeisevergutung: any, key: string) {
      const machinery: PriceItem[] = [];

      this.maschineAbweichendVomWindenergieanlagentarifItems.forEach((element: any, index) => {
        if (this.checkingMaschineAbweichendVomWindenergieanlagentarifItemFilled(element)) {
          const machineryItem = {
            type: 'maschine',
            machineCodeNumber: element.MKZ,
            codeSFPF: '6',
            codeABMGDiebstahl: '',
            assessmentYear: element.bewertungsjahr,
            evaluationPrice: element.versicherungssumme,
            codeVSu: '',
            codeSachAus: '',
            codeDeductibleVar1: element.selbstbeteiliung,
            CodeSbBuVar1: selbstbeteiligungsvariantenEinspeisevergutung.SBBUTage,
            discount1: 0,
            surcharge3: '0',
          };
          machinery.push(machineryItem);
        }
      });
      this.positionsdatenItems.forEach((element: any, index) => {
        const machineryItem = {
          type: 'positionsdaten',
          machineCodeNumber: element.MKZ,
          codeSFPF: (key === 'SBVariante3') ? element.betriebsunterbrechung.haftzeit : selbstbeteiligungsvariantenEinspeisevergutung.BUHaftzeit,
          codeABMGDiebstahl: '',
          assessmentYear: element.bewertungsjahr,
          evaluationPrice: element.versicherungssumme,
          codeVSu: '',
          codeSachAus: '',
          codeDeductibleVar1: (key === 'SBVariante3') ? element.selbstbeteiligung.SB : selbstbeteiligungsvariantenEinspeisevergutung.SB,
          CodeSbBuVar1: (key === 'SBVariante3') ? element.selbstbeteiligung.SBBUTage : selbstbeteiligungsvariantenEinspeisevergutung.SBBUTage,
          discount1: 0,
          surcharge3: '0',
        };
        machinery.push(machineryItem);
      });

      const priceData = {
        insuranceKey: this.key,
        machinery,
        awardOverview: {
          codePaymentMethod: this.zahlungsweise,
          bonusYear: this.faktorenstand,
        },
        erstrisikosummenItems: this.erstrisikosummenItems,
      };
      return priceData;
    },
    /* setPrice(key: string) {
      this.priceItemKey = key;
      const franchiseOptionsVariant = this.getSelbstbeteiligungsvariantenEinspeisevergutungByKey(key);

      const priceData = this.makePriceDataRequest(franchiseOptionsVariant, key);

      this.$emit('price-card-data', priceData, key);
    },
    getSelbstbeteiligungsvariantenEinspeisevergutungByKey(key: string): any {
      if (key === 'SBTarif') {
        return this.selbstbeteiligungsvariantenEinspeisevergutung?.SBTarif;
      }

      return this.selbstbeteiligungsvariantenEinspeisevergutung?.items[key];
    }, */
    savePriceResponse(key: string, PriceResponse: any) {
      this.priceResponseData[key] = PriceResponse;
    },
    fillPriceResponse(newVal, oldVal) {
      this.savePriceResponse(this.priceItemKey, newVal.Result);

      if (newVal.Result.Messages !== null) {
        let messages = '';

        newVal.Result.Messages.forEach((element: any, index) => {
          messages = `${messages + element} `;
        });
        this.openModal('errors', messages);
      }
      let currentIndex = 0;
      const spreadsheetData = copyObject(this.spreadsheetData);

      this.maschineAbweichendVomWindenergieanlagentarifItems.forEach((element: any, index) => {
        if (this.checkingMaschineAbweichendVomWindenergieanlagentarifItemFilled(element)) {
          // eslint-disable-next-line no-param-reassign
          spreadsheetData.maschineAbweichendVomWindenergieanlagentarifItems[index].nettobeitragVorRabattenUndZuschlagen = Number(newVal.Result.Maschinen[currentIndex]['Prämie_SB_Var1_Maschine']);
          currentIndex += 1;
        }
      });
      this.positionsdatenItems.forEach((element: any, index) => {
        // eslint-disable-next-line no-param-reassign
        spreadsheetData.positionsdatenItems[index].VSU371 = Number((Number(element.versicherungssumme) / Number(newVal.Result.Maschinen[currentIndex].Summenfaktor)).toFixed(0));
        currentIndex += 1;
      });

      this.$emit('spreadsheet-data-change', spreadsheetData, this.key, false, false);
    },
    setZeroToSkillVollmachten() {
      Object.keys(this.skillVollmachten).forEach((key: string) => {
        if (this.skillVollmachten[key]?.differenceСoverage) {
          this.skillVollmachten[key].differenceСoverage = 0;
        }
      });
      if (this.desiredVariant &&
        this.mapDesiredVariants[this.desiredVariant] &&
        this.promotionalRewards[this.mapPromotionalRewards[this.desiredVariant]]
      ) {
        const key = this.mapDesiredVariants[this.desiredVariant];
        this.skillVollmachten[key].estates = 0;
        this.skillVollmachten[key].surcharges = 0;
      }
    },
    calculateSkillSurchargesForCoInsurance(surcharge: number) {
      if (this.coInsuranceShow) {
        Object.keys(this.skillVollmachten).forEach((key: string) => {
          const premieFactor = +(this.promotionalRewards[this.mapSkillVollmachtenToPromotionalRewards[key]]?.pramieInklVorschadenfaktor || 0);
          const estates = (this.skillVollmachten[key]?.estates || 0) / 100;
          const surcharges = (this.skillVollmachten[key]?.surcharges || 0) / 100;

          const value = premieFactor * (1 - estates) * (1 + surcharges);

          if (value !== 0) {
            this.skillVollmachten[key].differenceСoverage = (surcharge / value) * 100;
          }
        });
      }
    },
    calculateSkillPowersOfAttorney() {
      if (
        this.alternativeDescriptionShow &&
        this.desiredVariant &&
        this.offerPriceCtKw > 0 &&
        this.mapDesiredVariants[this.desiredVariant] &&
        this.promotionalRewards[this.mapPromotionalRewards[this.desiredVariant]]
      ) {
        const key = this.mapDesiredVariants[this.desiredVariant];
        const promotionalRewardsValue = +this.promotionalRewards[this.mapPromotionalRewards[this.desiredVariant]]?.pramieInklVorschadenfaktor || 0;
        if (promotionalRewardsValue > 0) {
          const calculatedValue = this.offerPriceCtKw / promotionalRewardsValue;
          if (calculatedValue > 1) {
            this.skillVollmachten[key].estates = 0;
            this.skillVollmachten[key].surcharges = (calculatedValue - 1) * 100;
          } else {
            this.skillVollmachten[key].surcharges = 0;
            this.skillVollmachten[key].estates = (1 - calculatedValue) * 100;
          }
        }
      }
    },
    getXMLKey(variantParam: string) {
      let variant = 'tarif';
      if (variantParam === 'SBTarif') {
        variant = 'tarif';
      } else if (variantParam === 'SBVariante1') {
        variant = 'variant1';
      } else if (variantParam === 'SBVariante2') {
        variant = 'variant2';
      } else if (variantParam === 'SBVariante3') {
        variant = 'variant3';
      }
      return variant;
    },
    fillApiValuesData() {
      if (this.insuranceFormsApiData && this.insuranceFormsApiData.priceFormData) {
        recursiveFillData(this.zusammenfassung, this.insuranceFormsApiData.priceFormData.zusammenfassung);
        recursiveFillData(this.kalkulationsergebnis, this.insuranceFormsApiData.priceFormData.kalkulationsergebnis);
        recursiveFillData(this.ergebnisseTarifkalkulation, this.insuranceFormsApiData.priceFormData.ergebnisseTarifkalkulation);
        recursiveFillData(this.vorschadenhistorie, this.insuranceFormsApiData.priceFormData.vorschadenhistorie);
        recursiveFillData(this.skillVollmachten, this.insuranceFormsApiData.priceFormData.skillVollmachten);
      }
    },
    enqueue(functionName: string) {
      // Add an item to the end of the queue
      this.queue.push(functionName);
    },
    queueDownloadPDF() {
      this.onNextClick();
      this.enqueue('downloadPDF');
    },
    queueSendBigXML(variant) {
      this.variant = variant;
      this.saveZusammenfassungPDF('sendBigXML', variant);
      // this.enqueue('sendBigXML');
    },
    queueDownloadBigXML(variant) {
      this.variant = variant;
      this.saveZusammenfassungPDF('downloadBigXML', variant);
      // this.enqueue('downloadBigXML');
    },
    executeQueue() {
      // Iterate over the queue and execute each function
      // eslint-disable-next-line no-restricted-syntax
      for (const functionName of this.queue) {
        if (typeof this[functionName] === 'function') {
          this[functionName](); // Call the function
        }
      }
      // Clear the queue after executing all functions
      this.queue = [];
    },
    sendBigXML() {
      apiBackend.sendBigXML(this.id, {
        variant: this.variant,
      }).then((response) => {
        this.$emit('get-draft-insurance', this.id);
      });
    },
    downloadBigXML() {
      apiBackend.downloadBigXML(this.id, {
        variant: this.variant,
      }).then((response) => {
        const fileURL = window.URL.createObjectURL(new Blob([response.data]));
        const fileLink = document.createElement('a');
        fileLink.href = fileURL;
        fileLink.setAttribute('download', 'big_xml.xml');
        document.body.appendChild(fileLink);
        fileLink.click();
      });
    },
    exportToPDF() {
      const filename = `Zusammenfassung-Windenergieanlagen-Price-${this.id}.pdf`;
      this.pdfStyles = true;
      html2pdf(document.getElementById('element-to-convert'), {
        margin: 10,
        filename,
        html2canvas: {
          width: 1500,
        },
      }).then((response) => {
        this.pdfStyles = false;
      });
    },
    saveZusammenfassungPDF(functionName: string, variant: string) {
      const element = document.getElementById('element-to-convert');
      const filename = `Zusammenfassung-Windenergieanlagen-Price-${this.id}.pdf`;
      this.pdfStyles = true;
      const opt = {
        margin: 10,
        filename,
        html2canvas: {
          width: 1500,
        },
      };
      html2pdf().set(opt).from(element).output('datauristring')
        .then((response) => {
          const arr = response.split(',');
          const pfdStr = arr[1];
          const data = {
            insuranceId: this.id,
            pfdStr,
          };
          this.promotionalRewards[variant].sentXmlRequest = true;
          apiBackend.savePDF(data).then((r) => {
            this.enqueue(functionName);
            this.onNextClick();
          });

          this.pdfStyles = false;
        });
    },
    downloadPDF() {
      setTimeout(() => {
        apiBackend.downloadPDF(this.id).then((response) => {
          const fileURL = window.URL.createObjectURL(new Blob([response.data]));
          const fileLink = document.createElement('a');
          fileLink.href = fileURL;
          fileLink.setAttribute('download', `Vorschlag-${this.id}.pdf`);
          document.body.appendChild(fileLink);
          fileLink.click();
        });
      }, 1000);
    },
    openModal(title: string, body: string) {
      this.modalTitle = title;
      this.modalBody = body;
      this.modalOpen = !this.modalOpen;
    },
    modalClose() {
      this.modalTitle = '';
      this.modalBody = '';
      this.modalOpen = false;
    },
    calculateErgebnisseTarifkalkulation(item: any, tarifPramie = null) {
      const laufzeitCoeficient = (item.laufzeit === 3) ? 0.9 : 1;
      const SVRCoeficient = 1 - (item.SVR / 100);
      const nettoisierungCoeficient = 1 - (item.nettoisierung / 100);
      return Number(tarifPramie ?? item.tarifPramie) * laufzeitCoeficient * SVRCoeficient * nettoisierungCoeficient;
    },
    calculatePromotionalRewards() {
      // TODO: refactor this
      const calculate = (pramieInklTariflicherNachlasse, data, estates, surcharges, differenceСoverage) => {
        const { name, selbstbehalt } = data;
        const pramieInklVorschadenfaktor = (pramieInklTariflicherNachlasse * this.zusatzlicherVorschadenfaktorСoefficient);
        const pramieInklSkill = pramieInklVorschadenfaktor * (1 - estates * 0.01) * (1 + surcharges * 0.01) * (1 + differenceСoverage * 0.01);
        const angebotspramiensatzNetto = (pramieInklSkill / this.kalkulationsergebnis.versicherungssummeAktuell) * 1000;
        const angebotspramiensatzBrutto = pramieInklSkill * 1.19;
        const pramieInklSkillFloat = parseFloat(Number(pramieInklSkill).toFixed(2));
        const beitragCtKwNetto = this.totalOutputOfSystemsInKw !== 0 && this.alternativeDescriptionShow ?
          parseFloat(Number((pramieInklSkillFloat / this.totalOutputOfSystemsInKw) * 100).toFixed(2)) :
          0;

        return {
          name,
          selbstbehalt,
          pramieInklTariflicher: parseFloat(Number(pramieInklTariflicherNachlasse).toFixed(2)),
          pramieInklVorschadenfaktor: parseFloat(Number(pramieInklVorschadenfaktor).toFixed(2)),
          pramieInklSkill: pramieInklSkillFloat,
          angebotspramiensatzNetto: parseFloat(Number(angebotspramiensatzNetto).toFixed(2)),
          angebotspramiensatzBrutto: parseFloat(Number(angebotspramiensatzBrutto).toFixed(2)),
          beitragCtKwNetto,
        };
      };

      const deckungserweiterungenSurcharge = this.getDeckungserweiterungenSurcharge(this.insuranceData.spreadsheetData.deckungserweiterungen);
      const MaschineAbweichendSumm = this.getMaschineAbweichendVomWindenergieanlagentarifItemsSumm(this.insuranceData.spreadsheetData.maschineAbweichendVomWindenergieanlagentarifItems);
      const maschineLength = this.getMaschineAbweichendVomWindenergieanlagentarifItemsCount(this.insuranceData.spreadsheetData.maschineAbweichendVomWindenergieanlagentarifItems);
      const { priceResponseData } = this;

      if (priceResponseData.SBTarif && priceResponseData.SBTarif.Maschinen) {
        const PramieERSBruttoFactor = Number(priceResponseData.SBTarif['Prämienübersicht']['Prämie_ERS_Brutto']);
        const PositionsdatenSumm = this.getPramiePositionsdatenSumm(priceResponseData.SBTarif.Maschinen, this.insuranceData.spreadsheetData.positionsdatenItems, maschineLength);
        this.ergebnisseTarifkalkulation.tarif.tarifPramie = (PositionsdatenSumm + MaschineAbweichendSumm + PramieERSBruttoFactor) * deckungserweiterungenSurcharge;
        this.ergebnisseTarifkalkulation.tarif.pramieInklTariflicherNachlasse = parseFloat(Number(this.calculateErgebnisseTarifkalkulation(this.ergebnisseTarifkalkulation.tarif)).toFixed(2));
        this.maschinenItems.tarif = calculate(
          this.calculateErgebnisseTarifkalkulation(this.ergebnisseTarifkalkulation.tarif, (PositionsdatenSumm + MaschineAbweichendSumm) * deckungserweiterungenSurcharge),
          this.ergebnisseTarifkalkulation.tarif,
          this.skillVollmachten.TariffVariant.estates,
          this.skillVollmachten.TariffVariant.surcharges,
          this.skillVollmachten.TariffVariant.differenceСoverage,
        );
        this.erstrisikosummenPriceItems.tarif = calculate(
          this.calculateErgebnisseTarifkalkulation(this.ergebnisseTarifkalkulation.tarif, PramieERSBruttoFactor * deckungserweiterungenSurcharge),
          this.ergebnisseTarifkalkulation.tarif,
          this.skillVollmachten.TariffVariant.estates,
          this.skillVollmachten.TariffVariant.surcharges,
          this.skillVollmachten.TariffVariant.differenceСoverage,
        );
        this.promotionalRewards.tarif = calculate(
          this.calculateErgebnisseTarifkalkulation(this.ergebnisseTarifkalkulation.tarif),
          this.ergebnisseTarifkalkulation.tarif,
          this.skillVollmachten.TariffVariant.estates,
          this.skillVollmachten.TariffVariant.surcharges,
          this.skillVollmachten.TariffVariant.differenceСoverage,
        );
      }
      if (priceResponseData.SBVariante1 && priceResponseData.SBVariante1.Maschinen) {
        const PramieERSBruttoFactor = Number(priceResponseData.SBVariante1['Prämienübersicht']['Prämie_ERS_Brutto']);
        const PositionsdatenSumm = this.getPramiePositionsdatenSumm(priceResponseData.SBVariante1.Maschinen, this.insuranceData.spreadsheetData.positionsdatenItems, maschineLength);
        this.ergebnisseTarifkalkulation.SBVariante1.tarifPramie = (PositionsdatenSumm + MaschineAbweichendSumm + PramieERSBruttoFactor) * deckungserweiterungenSurcharge;
        this.ergebnisseTarifkalkulation.SBVariante1.pramieInklTariflicherNachlasse = parseFloat(Number(this.calculateErgebnisseTarifkalkulation(this.ergebnisseTarifkalkulation.SBVariante1)).toFixed(2));
        this.maschinenItems.SBVariante1 = calculate(
          this.calculateErgebnisseTarifkalkulation(this.ergebnisseTarifkalkulation.SBVariante1, (PositionsdatenSumm + MaschineAbweichendSumm) * deckungserweiterungenSurcharge),
          this.ergebnisseTarifkalkulation.SBVariante1,
          this.skillVollmachten.SBvariant1.estates,
          this.skillVollmachten.SBvariant1.surcharges,
          this.skillVollmachten.SBvariant1.differenceСoverage,
        );
        this.erstrisikosummenPriceItems.SBVariante1 = calculate(
          this.calculateErgebnisseTarifkalkulation(this.ergebnisseTarifkalkulation.SBVariante1, PramieERSBruttoFactor * deckungserweiterungenSurcharge),
          this.ergebnisseTarifkalkulation.SBVariante1,
          this.skillVollmachten.SBvariant1.estates,
          this.skillVollmachten.SBvariant1.surcharges,
          this.skillVollmachten.SBvariant1.differenceСoverage,
        );
        this.promotionalRewards.SBVariante1 = calculate(
          this.calculateErgebnisseTarifkalkulation(this.ergebnisseTarifkalkulation.SBVariante1),
          this.ergebnisseTarifkalkulation.SBVariante1,
          this.skillVollmachten.SBvariant1.estates,
          this.skillVollmachten.SBvariant1.surcharges,
          this.skillVollmachten.SBvariant1.differenceСoverage,
        );
      }
      if (priceResponseData.SBVariante2 && priceResponseData.SBVariante2.Maschinen) {
        const PramieERSBruttoFactor = Number(priceResponseData.SBVariante2['Prämienübersicht']['Prämie_ERS_Brutto']);
        const PositionsdatenSumm = this.getPramiePositionsdatenSumm(priceResponseData.SBVariante2.Maschinen, this.insuranceData.spreadsheetData.positionsdatenItems, maschineLength);
        this.ergebnisseTarifkalkulation.SBVariante2.tarifPramie = (PositionsdatenSumm + MaschineAbweichendSumm + PramieERSBruttoFactor) * deckungserweiterungenSurcharge;
        this.ergebnisseTarifkalkulation.SBVariante2.pramieInklTariflicherNachlasse = parseFloat(Number(this.calculateErgebnisseTarifkalkulation(this.ergebnisseTarifkalkulation.SBVariante2)).toFixed(2));
        this.maschinenItems.SBVariante2 = calculate(
          this.calculateErgebnisseTarifkalkulation(this.ergebnisseTarifkalkulation.SBVariante2, (PositionsdatenSumm + MaschineAbweichendSumm) * deckungserweiterungenSurcharge),
          this.ergebnisseTarifkalkulation.SBVariante2,
          this.skillVollmachten.SBvariant2.estates,
          this.skillVollmachten.SBvariant2.surcharges,
          this.skillVollmachten.SBvariant2.differenceСoverage,
        );
        this.erstrisikosummenPriceItems.SBVariante2 = calculate(
          this.calculateErgebnisseTarifkalkulation(this.ergebnisseTarifkalkulation.SBVariante2, PramieERSBruttoFactor * deckungserweiterungenSurcharge),
          this.ergebnisseTarifkalkulation.SBVariante2,
          this.skillVollmachten.SBvariant2.estates,
          this.skillVollmachten.SBvariant2.surcharges,
          this.skillVollmachten.SBvariant2.differenceСoverage,
        );
        this.promotionalRewards.SBVariante2 = calculate(
          this.calculateErgebnisseTarifkalkulation(this.ergebnisseTarifkalkulation.SBVariante2),
          this.ergebnisseTarifkalkulation.SBVariante2,
          this.skillVollmachten.SBvariant2.estates,
          this.skillVollmachten.SBvariant2.surcharges,
          this.skillVollmachten.SBvariant2.differenceСoverage,
        );
      }
      if (priceResponseData.SBVariante3 && priceResponseData.SBVariante3.Maschinen) {
        const PramieERSBruttoFactor = Number(priceResponseData.SBVariante3['Prämienübersicht']['Prämie_ERS_Brutto']);
        const PositionsdatenSumm = this.getPramiePositionsdatenSumm(priceResponseData.SBVariante3.Maschinen, this.insuranceData.spreadsheetData.positionsdatenItems, maschineLength);
        this.ergebnisseTarifkalkulation.SBVariante3.tarifPramie = (PositionsdatenSumm + MaschineAbweichendSumm + PramieERSBruttoFactor) * deckungserweiterungenSurcharge;
        this.ergebnisseTarifkalkulation.SBVariante3.pramieInklTariflicherNachlasse = parseFloat(Number(this.calculateErgebnisseTarifkalkulation(this.ergebnisseTarifkalkulation.SBVariante3)).toFixed(2));
        this.maschinenItems.SBVariante3 = calculate(
          this.calculateErgebnisseTarifkalkulation(this.ergebnisseTarifkalkulation.SBVariante3, (PositionsdatenSumm + MaschineAbweichendSumm) * deckungserweiterungenSurcharge),
          this.ergebnisseTarifkalkulation.SBVariante3,
          this.skillVollmachten.SBvariant3.estates,
          this.skillVollmachten.SBvariant3.surcharges,
          this.skillVollmachten.SBvariant3.differenceСoverage,
        );
        this.erstrisikosummenPriceItems.SBVariante3 = calculate(
          this.calculateErgebnisseTarifkalkulation(this.ergebnisseTarifkalkulation.SBVariante3, PramieERSBruttoFactor * deckungserweiterungenSurcharge),
          this.ergebnisseTarifkalkulation.SBVariante3,
          this.skillVollmachten.SBvariant3.estates,
          this.skillVollmachten.SBvariant3.surcharges,
          this.skillVollmachten.SBvariant3.differenceСoverage,
        );
        this.promotionalRewards.SBVariante3 = calculate(
          this.calculateErgebnisseTarifkalkulation(this.ergebnisseTarifkalkulation.SBVariante3),
          this.ergebnisseTarifkalkulation.SBVariante3,
          this.skillVollmachten.SBvariant3.estates,
          this.skillVollmachten.SBvariant3.surcharges,
          this.skillVollmachten.SBvariant3.differenceСoverage,
        );
      }
    },
    goToBackUrl() {
      const routeName = getProductPageBeforePriceTabHelper(this.insuranceKey);
      this.$emit('go-back-tab', routeName, 'insuranceForms', 2);
    },
    setValues(priceFormData: any) {
      this.zusammenfassung = priceFormData.zusammenfassung;
      this.kalkulationsergebnis = priceFormData.kalkulationsergebnis;
      this.ergebnisseTarifkalkulation = priceFormData.ergebnisseTarifkalkulation;
      this.vorschadenhistorie = priceFormData.vorschadenhistorie;
      this.skillVollmachten = priceFormData.skillVollmachten;
      this.vierAugenPrinzip = priceFormData.vierAugenPrinzip;
      this.promotionalRewards = priceFormData.promotionalRewards;
      this.hinweiseZumBeitrag = priceFormData.hinweiseZumBeitrag;
      this.alternativeDescriptionShow = priceFormData.alternativeDescriptionShow || false;
      this.coInsuranceShow = priceFormData.coInsuranceShow || false;
      this.totalOutputOfSystemsInKw = priceFormData.totalOutputOfSystemsInKw || 0;
      this.desiredContributionInCtKw = priceFormData.desiredContributionInCtKw || 0;
      this.desiredVariant = priceFormData.desiredVariant || null;
      this.offerPriceCtKw = priceFormData.offerPriceCtKw || 0;
      this.coInsuranceTotalOutputOfSystemsInKw = priceFormData.coInsuranceTotalOutputOfSystemsInKw || 0;
      this.coInsuranceSurchargeInCtKw = priceFormData.coInsuranceSurchargeInCtKw || 0;
      this.coInsuranceSurchargeInEur = priceFormData.coInsuranceSurchargeInEur || 0;

      /* if (priceFormData.priceResponseData) {
        this.priceResponseData = priceFormData.priceResponseData;
      } */
    },
    checkingMaschineAbweichendVomWindenergieanlagentarifItemFilled(item: any) {
      if ((item.MKZ === '' || item.MKZ === null || item.MKZ === '0') && (item.bewertungsjahr === '' || item.bewertungsjahr === null || item.bewertungsjahr === '0') && (item.versicherungssumme === 0 || item.versicherungssumme === null)) {
        return false;
      }
      return true;
    },
    getMaschineAbweichendVomWindenergieanlagentarifItemsSumm(maschineAbweichendVomWindenergieanlagentarifItems) {
      let summ = 0;
      maschineAbweichendVomWindenergieanlagentarifItems.forEach((item, key) => {
        if (this.checkingMaschineAbweichendVomWindenergieanlagentarifItemFilled(item)) {
          summ += Number(item.nettobeitragVorRabattenUndZuschlagen);
        }
      });
      return summ;
    },
    getMaschineAbweichendVomWindenergieanlagentarifItemsCount(maschineAbweichendVomWindenergieanlagentarifItems) {
      let coll = 0;
      maschineAbweichendVomWindenergieanlagentarifItems.forEach((item, key) => {
        if (this.checkingMaschineAbweichendVomWindenergieanlagentarifItemFilled(item)) {
          coll += 1;
        }
      });
      return coll;
    },
    getPramiePositionsdatenSumm(Maschinen, positionsdatenItems, startIndex) {
      let summ = 0;
      positionsdatenItems.forEach((item, key) => {
        const deckungsformSurchargeСoefficient = this.getDeckungsformSurchargeСoefficient(item);
        summ += Number(this.getPramiePositionsdatenItem(Number(Maschinen[key + startIndex]['Prämie_SB_Var1_Maschine'] * deckungsformSurchargeСoefficient), Number(Maschinen[key + startIndex]['Prämienfaktor']), item));
      });
      return summ;
    },
    getPramiePositionsdatenItem(pramieSBVar1Maschine: number, Pramienfaktor: number, positionsdatenItem) {
      let result = pramieSBVar1Maschine * (1 + positionsdatenItem.betriebsunterbrechung.zuschlagPercent / 100);
      result += positionsdatenItem.betriebsunterbrechung.zuschlagEUR;
      result *= (1 - positionsdatenItem.erweiterteDeckungsinformationen.rabattFurVollwartungsvertrag / 100);
      result *= (1 + positionsdatenItem.erweiterteDeckungsinformationen.zuschlagIn / 100);
      result *= (1 + positionsdatenItem.erweiterteDeckungsinformationen.GAPDeckung / 100);
      result *= (1 - positionsdatenItem.erweiterteDeckungsinformationen.individuellerNachlass / 100);
      result *= (1 + positionsdatenItem.erweiterteDeckungsinformationen.individuellerZuschlag / 100);
      result *= (1 + (positionsdatenItem.erweiterteDeckungsinformationen.mitversicherungInnereUnruhen * Pramienfaktor) / 1000);
      return Number(result);
    },
    getDeckungserweiterungenSurcharge(deckungserweiterungen) {
      return (1 + deckungserweiterungen.mitversicherungSamtlicheNaturgefahren.zuschlag / 100) * (1 + deckungserweiterungen.mitversicherungDerHoherhaftungInsBU.zuschlag / 100);
    },
    getDeckungserweiterungenSurchargeForShow(deckungserweiterungen, variantPrice) {
      const maschineLength = this.getMaschineAbweichendVomWindenergieanlagentarifItemsCount(this.insuranceData.spreadsheetData.maschineAbweichendVomWindenergieanlagentarifItems);
      if (variantPrice && variantPrice.Maschinen) {
        const PositionsdatenSumm = this.getPramiePositionsdatenSumm(variantPrice.Maschinen, this.insuranceData.spreadsheetData.positionsdatenItems, maschineLength);
        return (Number(deckungserweiterungen.mitversicherungDerHoherhaftungInsBU.zuschlag) / 100) * Number(PositionsdatenSumm);
      }
      return 0;
    },
    getDeckungsformSurchargeСoefficient(positionsdatenItem) {
      const { deckungsform } = positionsdatenItem;
      if (deckungsform === 'Maschinen + BU Volldeckung') {
        return 1;
      } if (deckungsform === 'Maschinen + BU Kaskodeckung' || deckungsform === 'Maschinen Volldeckung') {
        return 0.6;
      } if (deckungsform === 'Maschinen Kaskodeckung') {
        return 0.4;
      }
      return 1;
    },
    onNextClick(redirectToNextPage = true) {
      this.vorschadenhistorie.summe.schaden = this.vorschadenhistorieSummeSchaden;
      this.vorschadenhistorie.summe.reserven = this.vorschadenhistorieSummeReserven;
      this.vorschadenhistorie.MindestjahrespramieTarifVariante = this.claimsRequirementPremium;

      const data = {
        zusammenfassung: this.zusammenfassung,
        kalkulationsergebnis: this.kalkulationsergebnis,
        ergebnisseTarifkalkulation: this.ergebnisseTarifkalkulation,
        vorschadenhistorie: this.vorschadenhistorie,
        skillVollmachten: this.skillVollmachten,
        vierAugenPrinzip: this.vierAugenPrinzip,
        promotionalRewards: this.promotionalRewards,
        hinweiseZumBeitrag: this.hinweiseZumBeitrag,
        alternativeDescriptionShow: this.alternativeDescriptionShow,
        coInsuranceShow: this.coInsuranceShow,
        totalOutputOfSystemsInKw: this.totalOutputOfSystemsInKw,
        desiredContributionInCtKw: this.desiredContributionInCtKw,
        desiredVariant: this.desiredVariant,
        offerPriceCtKw: this.offerPriceCtKw,
        coInsuranceTotalOutputOfSystemsInKw: this.coInsuranceTotalOutputOfSystemsInKw,
        coInsuranceSurchargeInCtKw: this.coInsuranceSurchargeInCtKw,
        coInsuranceSurchargeInEur: this.coInsuranceSurchargeInEur,
        // priceResponseData: this.priceResponseData,
      };
      this.$emit('price-formData-changed', data);
    },
    fillAngebotspramien(selbstbeteiligungsvariantenEinspeisevergutung) {
      this.promotionalRewards.SBVariante1.selbstbehalt = selbstbeteiligungsvariantenEinspeisevergutung.items.SBVariante1.SB;
      this.ergebnisseTarifkalkulation.SBVariante1.selbstbehalt = selbstbeteiligungsvariantenEinspeisevergutung.items.SBVariante1.SB;
      this.promotionalRewards.SBVariante2.selbstbehalt = selbstbeteiligungsvariantenEinspeisevergutung.items.SBVariante2.SB;
      this.ergebnisseTarifkalkulation.SBVariante2.selbstbehalt = selbstbeteiligungsvariantenEinspeisevergutung.items.SBVariante2.SB;
    },
    fillErgebnisseTarifkalkulation(priceResponseData) {
      const deckungserweiterungenSurcharge = this.getDeckungserweiterungenSurcharge(this.insuranceData.spreadsheetData.deckungserweiterungen);
      const MaschineAbweichendSumm = this.getMaschineAbweichendVomWindenergieanlagentarifItemsSumm(this.insuranceData.spreadsheetData.maschineAbweichendVomWindenergieanlagentarifItems);
      const maschineLength = this.getMaschineAbweichendVomWindenergieanlagentarifItemsCount(this.insuranceData.spreadsheetData.maschineAbweichendVomWindenergieanlagentarifItems);
      if (priceResponseData.SBTarif && priceResponseData.SBTarif.Maschinen) {
        const PramieERSBruttoFactor = Number(priceResponseData.SBTarif['Prämienübersicht']['Prämie_ERS_Brutto']);
        const PositionsdatenSumm = this.getPramiePositionsdatenSumm(priceResponseData.SBTarif.Maschinen, this.insuranceData.spreadsheetData.positionsdatenItems, maschineLength);
        this.ergebnisseTarifkalkulation.tarif.tarifPramie = (PositionsdatenSumm + MaschineAbweichendSumm + PramieERSBruttoFactor) * deckungserweiterungenSurcharge;
      }
      if (priceResponseData.SBVariante1 && priceResponseData.SBVariante1.Maschinen) {
        const PramieERSBruttoFactor = Number(priceResponseData.SBVariante1['Prämienübersicht']['Prämie_ERS_Brutto']);
        const PositionsdatenSumm = this.getPramiePositionsdatenSumm(priceResponseData.SBVariante1.Maschinen, this.insuranceData.spreadsheetData.positionsdatenItems, maschineLength);
        this.ergebnisseTarifkalkulation.SBVariante1.tarifPramie = (PositionsdatenSumm + MaschineAbweichendSumm + PramieERSBruttoFactor) * deckungserweiterungenSurcharge;
      }
      if (priceResponseData.SBVariante2 && priceResponseData.SBVariante2.Maschinen) {
        const PramieERSBruttoFactor = Number(priceResponseData.SBVariante2['Prämienübersicht']['Prämie_ERS_Brutto']);
        const PositionsdatenSumm = this.getPramiePositionsdatenSumm(priceResponseData.SBVariante2.Maschinen, this.insuranceData.spreadsheetData.positionsdatenItems, maschineLength);
        this.ergebnisseTarifkalkulation.SBVariante2.tarifPramie = (PositionsdatenSumm + MaschineAbweichendSumm + PramieERSBruttoFactor) * deckungserweiterungenSurcharge;
      }
      if (priceResponseData.SBVariante3 && priceResponseData.SBVariante3.Maschinen) {
        const PramieERSBruttoFactor = Number(priceResponseData.SBVariante3['Prämienübersicht']['Prämie_ERS_Brutto']);
        const PositionsdatenSumm = this.getPramiePositionsdatenSumm(priceResponseData.SBVariante3.Maschinen, this.insuranceData.spreadsheetData.positionsdatenItems, maschineLength);
        this.ergebnisseTarifkalkulation.SBVariante3.tarifPramie = (PositionsdatenSumm + MaschineAbweichendSumm + PramieERSBruttoFactor) * deckungserweiterungenSurcharge;
      }
    },
    initSentXmlRequest() {
      Object.keys(this.promotionalRewards).forEach((key) => {
        this.promotionalRewards[key].sentXmlRequest = false;
      });
    },
    calculateTotalOutputOfSystemsInKw(data: any[]): number {
      return data.reduce((partialSum, item) => partialSum + (+item.installierteLeistung), 0);
    },
    onCoInsuranceSurchargeInCtKwChange() {
      this.calculateCoInsuranceSurchargeInEur();
    },
    onCoInsuranceTotalOutputOfSystemsInKwChange() {
      this.calculateCoInsuranceSurchargeInEur();
    },
    calculateCoInsuranceSurchargeInEur() {
      this.coInsuranceSurchargeInEur = (this.coInsuranceTotalOutputOfSystemsInKw * this.coInsuranceSurchargeInCtKw) / 100;
    },
    fillZusammenfassung() {
      if (!this.filledZusammenfassung && this.insuranceData?.spreadsheetData) {
        if (this.insuranceData?.spreadsheetData?.versicherungssummeWEA) {
          this.zusammenfassung.versicherungssummeAktuell = this.insuranceData.spreadsheetData.versicherungssummeWEA;
        }

        if (this.insuranceData?.spreadsheetData?.Nettoisierung) {
          this.zusammenfassung.nettoisiert = this.insuranceData.spreadsheetData.Nettoisierung;
        }

        this.filledZusammenfassung = true;
      }

      this.zusammenfassung.versicherungssumme371 = this.sum371;
    },
  },
  mounted() {
    (this as any).resetWatcherActivated();
    this.fillZusammenfassung();
    if (this.insuranceData.priceFormData) {
      this.setValues(this.insuranceData.priceFormData);
      this.fillErgebnisseTarifkalkulation(this.priceResponseData);
    }

    this.initSentXmlRequest();
    (this as any).startWatcherActivated();
  },
  beforeDestroy() {
    if (this.isPCUser) return;
    this.onNextClick();
  },
});
